<template>
  <div class="goTop">
    <div class="cont">
      <el-backtop target=".goTop">
        <i class="el-icon-caret-top"></i>
      </el-backtop>
      <Header :title="$t('message.link.history')" />
      <img src="http://image.wearetogether.com.cn/hisBg-ukraine.jpg" alt="" class="img">
      <div class="next">
        <img
          src="http://cdn.topartsintermational.com/hisLine.png"
          class="img"
        />
        <h1 class="title">{{ setting.part1.title }}</h1>
        <div class="divider"><span></span></div>
        <div class="video">
          <video
            src="http://cdn.topartsintermational.com/history-show.mp4"
            controls
            width="100%"
          ></video>
        </div>
        <div class="des">{{ setting.part1.des1 }}</div>
        <div class="des">{{ setting.part1.des2 }}</div>
        <div
          class="title"
          style="text-align: left; width: 1200px; margin: 16px auto 0"
        >
          <span style="border-bottom: 2px solid #bc924f">{{
            setting.part2.title
          }}</span>
        </div>
        <div class="des">{{ setting.part2.des1 }}</div>
        <div class="all">
          <img
            src="http://cdn.topartsintermational.com/his-img.png"
            width="48%"
          />
          <div class="all-des">
            <div>{{ setting.part2.des2 }}</div>
            <br />
            <div>{{ setting.part2.des3 }}</div>
          </div>
        </div>
        <div class="des">{{ setting.part2.des4 }}</div>
        <div class="des">{{ setting.part2.des5 }}</div>
        <div class="dif">
          <div class="dif-cont">
            <span class="dif-title">{{ setting.part3.title }}</span>
            <div class="dif-des">{{ setting.part3.des1 }}</div>
            <div class="dif-des">{{ setting.part3.des2 }}</div>
          </div>
        </div>
        <div class="ter">
          <div class="ter-left">
            <span class="ter-title">{{ setting.part4.title }}</span>
            <div class="ter-des">{{ setting.part4.des }}</div>
          </div>
          <div class="ter-right">
            <img
              src="http://cdn.topartsintermational.com/ter-img1.png"
              width="170px"
            />
            <img
              src="http://cdn.topartsintermational.com/ter-img2.png"
              width="280px"
            />
          </div>
        </div>
        <img src="http://cdn.topartsintermational.com/hisLine.png" width="100%" />
        <div class="pho">
          <h1 class="title">{{ setting.photo }}</h1>
          <div class="wall">
            <div class="wall-box">
              <img src="http://cdn.topartsintermational.com/1.jpg" width="225x" />
            </div>
            <div class="wall-box">
              <img
                src="http://cdn.topartsintermational.com/2.jpg"
                width="225px"
              />
            </div>
            <div class="wall-box">
              <img
                src="http://cdn.topartsintermational.com/3.jpg"
                width="225px"
              />
            </div>
          </div>
          <div class="wall">
            <div class="wall-box">
              <img
                src="http://cdn.topartsintermational.com/4.jpg"
                width="225px"
              />
            </div>
            <div class="wall-box">
              <img
                src="http://cdn.topartsintermational.com/5.jpg"
                width="225px"
              />
            </div>
            <div class="wall-box">
              <img
                src="http://cdn.topartsintermational.com/7.jpg"
                width="225px"
              />
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
    <!-- 模态框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="1200px"
      :show-close="false"
      custom-class="dialog"
      destroy-on-close
    >
      <video
        src="http://image.wearetogether.com.cn/history-fir.mp4"
        controls
        width="1200px"
        style="display: block"
      ></video>
    </el-dialog>
  </div>
</template>

<script>
import Header from "../../components/header"
import Footer from "../../components/footer"
export default {
  name: "History",
  data () {
    return {
      setting: {
        // 第一部分
        part1: {
          title: this.$t('message.link.history'),
          des1: this.$t('message.history.part1.des1'),
          des2: this.$t('message.history.part1.des2'),
        },
        // 第二部分
        part2: {
          title: this.$t('message.history.part2.title'),
          des1: this.$t('message.history.part2.des1'),
          des2: this.$t('message.history.part2.des2'),
          des3: this.$t('message.history.part2.des3'),
          des4: this.$t('message.history.part2.des4'),
          des5: this.$t('message.history.part2.des5'),
        },
        // 第三部分
        part3: {
          title: this.$t('message.history.part3.title'),
          des1: this.$t('message.history.part3.des1'),
          des2: this.$t('message.history.part3.des2'),
        },
        // 第四部分
        part4: {
          title: this.$t('message.history.part4.title'),
          des: this.$t('message.history.part4.des'),
        },
        // 照片墙
        photo: this.$t('message.history.photo'),
      },
      dialogVisible: true
    }
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
.goTop {
  min-width: 1240px;
  height: 100vh;
  overflow-x: hidden;
}
.img {
  display: block;
  width: 100%;
}
.cont {
  /* background: url("http://image.wearetogether.com.cn/hisBg-ukraine.jpg") no-repeat; */
  /* background-size: contain; */
  box-sizing: border-box;
  background-color: #4a0e10;
}
.next {
  background: url('http://image.wearetogether.com.cn/hisbg-bg.jpg') no-repeat;
  background-size: contain;
}
.title {
  font-size: 24px;
  color: #fff;
  text-align: center;
  margin: 24px 0 0 0;
}
.divider {
  padding: 16px 0;
}
.divider span {
  display: block;
  height: 2px;
  width: 150px;
  background: #bc924f;
  margin: auto;
}
.video {
  width: 1200px;
  margin: auto;
}
.des {
  width: 1200px;
  margin: 16px auto 0;
  color: #fff;
  font-size: 14px;
}
.all {
  width: 1200px;
  margin: 16px auto 0;
  color: #fff;
  display: flex;
  justify-content: space-between;
}
.all-des {
  width: 48%;
  font-size: 14px;
}
.dif {
  background-image: url("http://cdn.topartsintermational.com/his-img1.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.dif-cont {
  width: 1200px;
  padding: 16px 0;
  margin: 16px auto 0;
  color: #fff;
}
.dif-title {
  border-bottom: 2px solid #bc924f;
  font-size: 24px;
}
.dif-des {
  margin-top: 16px;
  font-size: 14px;
}
.ter {
  width: 1200px;
  margin: auto;
  padding: 16px 0;
  display: flex;
}
.ter-left {
  width: 720px;
  color: #fff;
}
.ter-title {
  border-bottom: 2px solid #bc924f;
  font-size: 24px;
}
.ter-des {
  font-size: 14px;
  margin-top: 16px;
}
.ter-right {
  width: 480px;
  display: flex;
  justify-content: space-around;
}
.pho {
  width: 1200px;
  margin: auto;
  padding: 16px 0;
}
.wall {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.wall-box {
  overflow: hidden;
}
.wall img {
  transition: all 0.6s;
  cursor: pointer;
}
.wall img:hover {
  transform: scale(1.2);
  transition: all 0.6s;
}
</style>